<template>
  <div>
    <h1>Sorry. Page not found :(</h1>
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
